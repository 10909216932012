import React from "react"
import SEO from "../components/seo"
import netlifyIdentity from 'netlify-identity-widget';

//components

//material-ui
import Box from '@material-ui/core/Box'

//styles
import styles from "../styles/index.module.css"

if (typeof window !== 'undefined' && typeof netlifyIdentity.init !== `undefined`) {
    netlifyIdentity.init();
}

function NotFoundPage(props) {

    return (
        <Box width="auto" className={styles.box}>
            <SEO title="Soko" />
            <a className={styles.mail} href="mailto:cordell.blackford@gmail.com">
                Not Found
            </a>
        </Box>
    )
}

export default NotFoundPage
